.intervalButton {
  padding: 5px;
  font-family: "Inter", sans-serif;
  transition: all 0.2s ease;
}
.selectedIntervalButton {
  padding: 5px 10px;
  background-color: #1d3557;
  color: white;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  transition: all 0.2s ease;
}
.intervalButton:hover,
.selectedIntervalButton:hover {
  cursor: pointer;
}

.card {
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.card-header {
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Remove shadows from other elements as needed */

.livePriceCardTitle {
  font-family: 'Merriweather', serif;
  /* Add other styles as needed */
}
