.technical-indicators-plot {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  text-align: left;
  font-family: 'Merriweather', serif;
}

.ticker-input {
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chart-controls {
  margin-bottom: 15px;
  text-align: left;
}

.chart-selector, .field-selector {
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.field-selector {
  margin-bottom: 15px;
}

.field-option {
  margin: 5px;
  display: inline-block;
  font-size: 10px;
}

.field-option input[type="checkbox"] {
  accent-color: black;
  transform: scale(0.8);
}

.field-option input[type="checkbox"]:checked {
  background-color: black;
  border-color: black;
}

.error-message {
  color: red;
  margin: 10px 0;
}

.tradingview-widget {
  margin: 0 auto;
  max-width: 100%;
}

.technical-indicators-plot h3 {
  margin-bottom: 15px;
  text-align: left;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  z-index: 1000;
  font-size: 12px;
}

.chart-container {
  overflow: visible;
}

.chart-controls select {
  font-size: 14px;
  background-color: white;
}

.chart-controls select option {
  padding: 8px;
}

.chart-controls select option:checked {
  background-color: #e6f3ff;
}

.chart-controls select:focus {
  outline: none;
  border-color: #139C8D;
  box-shadow: 0 0 3px rgba(19, 156, 141, 0.3);
}

.custom-select {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-bottom: 8px;
}

.select-header {
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  font-size: 12px;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
}

.select-option {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 10px;
}

.select-option:hover {
  background-color: #f5f5f5;
}

.select-option input[type="checkbox"] {
  margin-right: 6px;
  transform: scale(0.8);
  accent-color: black;
}

.select-option input[type="checkbox"]:checked {
  background-color: black;
  border-color: black;
}
